import React from 'react'

import AboutMe from 'components/AboutMe'
import Footer from 'components/Footer'
import Header from 'components/Header'

function Application() {
  return (
    <>
      <Header />
      <AboutMe />
      <Footer />
    </>
  )
}

export default Application
